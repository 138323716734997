import "jspdf-autotable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import * as FileSaver from "file-saver";
import { jsPDF } from "jspdf";
import * as _ from "lodash";
import * as XLSX from "xlsx";
import { environment } from "../../../environments/environment";
import { map, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
const pdf_EXTENSION = ".pdf";
@Injectable({
  providedIn: "root",
})
export class dashboardService {
  [x: string]: any;
  pdf: any;

  result = [];
  head = [["cityId", "cityName", "cityCode", "countryId", "stateName"]];

  //readonly baseURL = 'http://localhost:3636';

  constructor(private http: HttpClient) {}

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  createPdf() {
    this.getCities().subscribe((data: any) => {
      this.result = data.cities;

      console.log(this.result);
      var pdfValue = this.result.map(Object.values);

      // var headvalues = this.PDFDownloadApi;
      console.log(pdfValue);

      var doc = new jsPDF("p", "pt");

      doc.setFontSize(18);
      doc.text("City Report", 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);

      (doc as any).autoTable({
        head: this.head,
        body: pdfValue,

        theme: "plain",
        startY: 30,

        bodyStyles: {
          valign: "top",
        },

        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },

        columnStyles: {
          text: {
            cellWidth: "auto",
          },

          didDrawCell: (data) => {
            console.log(data.column.keys, data);
          },
        },
      });

      //  PDF NEW WINDow
      doc.output("dataurlnewwindow");

      // Download PDF
      doc.save("cityreport.pdf");
    });
  }
  head1 = [
    [
      "id",
      "productDetails",
      "cityName",
      "categoriesName",
      "offerStarts",
      "offerEnds",
    ],
  ];
  createRecentOfferPdf() {
    this.getRecentOffers().subscribe((data: any) => {
      this.result = data.offers;
      //this data.offers = offers comes from ongridready gridData result['offers'] //

      console.log("recentoffer result-->", this.result);
      var pdfValue = this.result.map(Object.values);

      // var headvalues = this.PDFDownloadApi;
      console.log("Recent Offers", pdfValue);

      var doc = new jsPDF("p", "pt");

      doc.setFontSize(18);
      doc.text("Recent Offers", 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);

      (doc as any).autoTable({
        head: this.head1,
        body: pdfValue,

        theme: "plain",
        startY: 30,

        bodyStyles: {
          valign: "top",
        },

        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },

        columnStyles: {
          text: {
            cellWidth: "auto",
          },

          didDrawCell: (data) => {
            console.log(data.column.keys, data);
          },
        },
      });

      //  PDF NEW WINDow
      doc.output("dataurlnewwindow");

      // Download PDF
      doc.save("recentOffers.pdf");
    });
  }

  // head2 = [['id','batchId','batchTxnSeqNo','txnDate','txnAmount',"ledgerStartAmount","ledgerEndAmount"]]
  createWeeklyEarningPdf() {
    this.getWeeklyEarning().subscribe((data: any) => {
      this.result = data.weeklyEarnings;

      // console.log("weeklyEarning result-->", this.result);
      var pdfValue = this.result.map(Object.values);

      // var headvalues = this.PDFDownloadApi;
      // console.log("Weekly Earning", pdfValue);

      var doc = new jsPDF("p", "pt");

      doc.setFontSize(18);
      doc.text("Weekly Earnings", 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);

      (doc as any).autoTable({
        // head: this.head2,
        body: pdfValue,

        theme: "plain",
        startY: 30,

        bodyStyles: {
          valign: "top",
        },

        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },

        columnStyles: {
          text: {
            cellWidth: "auto",
          },

          didDrawCell: (data) => {
            // console.log(data.column.keys, data);
          },
        },
      });

      //  PDF NEW WINDow
      doc.output("dataurlnewwindow");

      // Download PDF
      doc.save("weeklyEarning.pdf");
    });
  }

  getDropDownText(id, object) {
    const selObj = _.filter(object, function (o) {
      return _.includes(id, o.id);
    });
    return selObj;
  }

  getVendorRegistration(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-trans/getVendorRegistration",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  
  getCompanyTypes(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getCompanyTypes",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  getChampionLists(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-master/getChampionLists",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  getProductOffers(filter: object = {}) { 
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/firebase/getplan",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  getCities(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getCities",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  
  getEncashedOffers(staffId: string, userGroupName: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    
    return this.http.post<any>(
      environment.apiCredentials.apiDomain + "/firebase/getEncashedOffers",
      JSON.stringify({ staffId, userGroupName }),
      { headers: headers }
    );
  }

  getOffers(filter: object = {}) {
   
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post<any>(
      environment.apiCredentials.apiDomain + "/firebase/getCandidOffers",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

 
  // dashboard.service.ts
// dashboard.service.ts
getChampionEarnings(staffId: string) {
  const userToken = localStorage.getItem("userToken");
  const headers = new HttpHeaders({
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  });
  
  // Use POST with staffId in the body
  return this.http.post(
    `${environment.apiCredentials.apiDomain}/co-trans/champion-earnings`,
    { staffId },  // Pass staffId in request body
    { headers: headers }
  );
}
  getLedgerPassbookData(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post<any>(
      environment.apiCredentials.apiDomain + "/co-trans/ledger-passbook",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  
  getVendors(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post<any>(
      environment.apiCredentials.apiDomain + "/firebase/getCandidVendors",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  
  getCustomers(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post<any>(
      environment.apiCredentials.apiDomain + "/firebase/getCandidCustomers",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

 // Update the getFilteredOffers method in your dashboardService
 getFilteredOffers(staffId: string): Observable<any[]> {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userGroupName = userData.user.groupName;

  if (userGroupName !== 'Champion' && userGroupName !== 'Super Champion' && userGroupName 
    !== 'DEVELOPER' && userGroupName !== 'SUPER ADMIN' && userGroupName !== 'ADMIN') {
    return of([]);
  }

  return this.getOffers().pipe(
    switchMap((offersResponse: any) => {
      const offers = Object.values(offersResponse || {})[1];
      // console.log("Offers:", offers);

      let filteredOffers = Array.isArray(offers) ? offers : [];
      if (userGroupName !== 'DEVELOPER' && userGroupName !== 'SUPER ADMIN' && userGroupName !== 'ADMIN') {
        filteredOffers = filteredOffers.filter((offer: any) => 
          offer?.offerStatus?.toLowerCase() === 'live'
        );
      }
      // console.log("Filtered Offers:", filteredOffers);

      return this.getVendors().pipe(
        map((vendorsResponse: any) => {
          const vendors = Object.values(vendorsResponse || {})[1];
          this.vendors = vendors; // Store vendors data for later use
          // console.log("Vendors:", vendors);

          let finalOffers;
          if (userGroupName === 'DEVELOPER' || userGroupName === 'SUPER ADMIN' || userGroupName === 'ADMIN') {
            finalOffers = filteredOffers.map((offer: any) => ({
              ...offer,
              cityName: offer?.selectedCity || 'Unknown City'
            }));
          } else {
            const referredVendors = Array.isArray(vendors)
              ? vendors.filter((vendor: any) => vendor?.referredBy === staffId)
              : [];
            // console.log("Referred Vendors:", referredVendors);

            const referredVendorIds = referredVendors.map((vendor: any) => vendor?.id);
            // console.log("Referred Vendor IDs:", referredVendorIds);

            finalOffers = filteredOffers
              .filter((offer: any) => referredVendorIds.includes(offer?.vendorId))
              .map((offer: any) => ({
                ...offer,
                cityName: offer?.selectedCity || 'Unknown City'
              }));
          }

          // console.log("Final Filtered Offers:", finalOffers);
          return finalOffers;
        })
      );
    })
  );
}


getWeeklyEarning(filter: object = {}) {     let headers = new HttpHeaders({      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });   
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-trans/getWeeklyEarning",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  commonApi(url, filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + url,
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  getKitIssues(filter: object = {}) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userGroupName = userData.user.groupName;

    if (
      userGroupName !== "DEVELOPER" &&
      userGroupName !== "SUPER ADMIN" &&
      userGroupName !== "ADMIN"
    ) {
      const staffId = userData.user.staffId;
      let type;
      if (userGroupName === 'Champion') {
        type = 'C';
      } else if (userGroupName === 'Super Champion') {
        type = 'S';
      } else {
        type = 'V';
      }
      filter = { kitIssueToType: type, kitIssueUserId: staffId };
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('userToken')
    });

    return this.http.post(
      `${environment.apiCredentials.apiDomain}/inv-trans/getKitIssue`,
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  getRecentOffers(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-trans/getRecentOffers",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  //Following methos is for the service call to get the Companies For Record List / Autocomplete
  getStates(filter: any = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getStates",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  //Following methos is for the service call to get the User For Record List / Autocomplete
  getUserTargets(filter: any = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getUserTargets",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  getVendorLists(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-master/getVendorLists",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  
  getOrders(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-trans/getOrders",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  updateEarningsData(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('userToken')
    });
    
    const url = `${environment.apiCredentials.apiDomain}/co-trans/updateEarningsData`;
    
    return this.http.post(url, data, { headers });
  }


  downloadFile(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getCities",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  backendAPICall(eventName: string = "", dataObj: any = {}) {
    if (eventName) {
      let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      });
      return this.http.post(
        environment.apiCredentials.apiDomain + "/support/" + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }

  checkRewardTarget(username: string): Observable<any> {
    return this.http.post<any>(
        `${environment.apiCredentials.apiDomain}/co-trans/updateUserRewardTarget`,
        { referralId: username },
        {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('userToken')
            })
        }
    );
  }
}
