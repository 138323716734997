import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { retry } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { AlertService } from "./alert.service";
import { ListActionsComponentService } from "./list-actions-component.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public token: string = "Test Token";
  constructor(
    private http: HttpClient,
    private router: Router,
    private alertService: AlertService,
    private listActionsComponentService: ListActionsComponentService
  ) {}

  // Following Is Common Event For api
  backendAPICall(eventName: string = "", dataObj: any = {}) {
    if (eventName) {
      let headers = new HttpHeaders({ "Content-Type": "application/json" });
      return this.http.post(
        environment.apiCredentials.apiDomain + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }
  uploadImage(url: string, formData: FormData) {
    if (url) {
      return this.http.post(
        environment.apiCredentials.apiDomain + url.trim(),
        formData
      );
    }
  }
  backendApiAuth(eventName: string = "", dataObj: any = {}) {
    if (eventName) {
      let headers = new HttpHeaders({ "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"), });
      return this.http.post(
        environment.apiCredentials.apiDomain + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }

  apiAuthenticate() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/apiAuth",
        JSON.stringify({
          user: environment.apiCredentials.user,
          pass: environment.apiCredentials.pass,
          key: environment.apiCredentials.key,
        }),
        { headers: headers }
      )
      .pipe(retry(2));
  }
  userAuthentication(user) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/userAuth",
        JSON.stringify({
          clientId: user.clientId,
          username: user.username,
          password: user.password,
        }),
        { headers: headers }
      )
      .pipe(retry(2));
  }

  userTokenAuthentication() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/userSessionAuth",
        JSON.stringify({}),
        { headers: headers }
      )
      .pipe();
  }

  getUserMenus(forModuleId) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/getUserMenus",
        JSON.stringify({
          forModuleId: forModuleId,
        }),
        { headers: headers }
      )
      .pipe();
  }
  documentRequiredCheck(filter) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/support/documentRequired",
        JSON.stringify(filter),
        { headers: headers }
      )
      .pipe();
  }

  signUpUser(userObj) {
    // console.log('common service userobj',userObj ) ;
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/addUser",
        JSON.stringify({
          //    clientId:userObj.clientId,
          username: userObj.userName,
          password: userObj.password,
          cityId: userObj.cityId,
          email: userObj.email,
          gender: userObj.gender,
          mobile1: userObj.mobile1,
          userrole: userObj.LoginAs,
        }),
        { headers: headers }
      )
      .pipe();
  }

  updateCurrentSelectionData(currentSelectionData) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain +
          "/common/updateUserCurrentSelection",
        JSON.stringify({
          currentSelectionData: currentSelectionData,
        }),
        { headers: headers }
      )
      .pipe();
  }

  logout() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/userLogout",
        JSON.stringify({}),
        { headers: headers }
      )
      .pipe(retry(2));
  }

  redirectToLogin(): void {
    this.alertService.create(
      "Session Expired",
      "error",
      20,
      "Your session expired, please login again.",
      () => {
        localStorage.removeItem("userToken");
        localStorage.removeItem("isLogged");
        localStorage.removeItem("userData");
        localStorage.removeItem("currentBranchData");
        localStorage.removeItem("currentCompanyData");
        localStorage.removeItem("currentModuleData");
        localStorage.removeItem("currentFinancialYear");
        localStorage.removeItem("menuActionsData");
        //console.log('callback');
        this.router.navigate(["/"]);
      }
    );
  }

  subscribeMenuActionsToEvent(actionDataForMenu, forMenu): Array<any> {
    let actionSubscription: Array<any> = [];
    if (actionDataForMenu) {
      for (let [key, actObj] of Object.entries(actionDataForMenu)) {
        //console.log(actObj['clickEvent']);
        actionSubscription[key] = this.listActionsComponentService
          .on(actObj["clickEvent"])
          .subscribe(() => forMenu[`${actObj["clickEvent"]}`]());
      }
    }
    return actionSubscription;
  }

  unSubscribeMenuActions(subscriberObj) {
    for (let k in subscriberObj) {
      subscriberObj[k].unsubscribe();
    }
  }

  setAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched();
    for (let i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        group.controls[i].markAsTouched();
      } else {
        this.setAsTouched(group.controls[i]);
      }
    }
  }

  getCities(filter: object = { status: "A" }) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/getCities",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getStates(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/getStates",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  
  tempAuth(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/tempAuth",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getChampions(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/getChampions",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  addChampion(token: string = "", eventName: string = "", dataObj: any = {}) {
    if (eventName) {
      let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      });
      return this.http.post(
        environment.apiCredentials.apiDomain + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }
  
  championSubscriptionPackageRequest(
    token: string = "",
    eventName: string = "",
    dataObj: any = {}
  ) {
    if (eventName) {
      let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      return this.http.post(
        environment.apiCredentials.apiDomain + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }
}
